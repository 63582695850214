import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { SearchRateTablesResponseDto } from '../../api/rateTable/rateTable.model';

export interface RateTableState {
  rateTableList: SearchRateTablesResponseDto[];
  totalRateTables: number;
}

const initialState: RateTableState = {
  rateTableList: [],
  totalRateTables: 0
};

export const rateTableSlice = createSlice({
  name: 'rateTable',
  initialState,
  reducers: {
    setRateTableList: (state, action: PayloadAction<SearchRateTablesResponseDto[]>) => {
      state.rateTableList = action.payload;
    },
    setTotalRateTables: (state, action: PayloadAction<number>) => {
      state.totalRateTables = action.payload;
    }
  }
});

export const selectRateTableList = (state: RootState): SearchRateTablesResponseDto[] =>
  state.rateTable.rateTableList;
export const selectTotalRateTables = (state: RootState): number => state.rateTable.totalRateTables;

export const { setRateTableList, setTotalRateTables } = rateTableSlice.actions;

export default rateTableSlice.reducer;
