import { string } from 'yup';
import { InvoiceState } from '../../../providers/Invoices/Invoices.model';
import {
  LoadNotificationSettingsRequest,
  LoadNotificationSettingsResponse
} from '../../../providers/Settings/LoadNotificationSettings.model';
import { BaseQueryParams } from '../../Global.model';
import { ClientResponse } from '../customer/customer.model';
import { DispatcherResponse } from '../dispatchers/dispatcher.model';
import { IDivisionsResponse } from '../divisions/divisions.model';
import { InvoiceServiceUnit } from '../invoiceService/invoiceService.model';
import {
  AttachmentCopy,
  ImportedLoadAttachmentData,
  LOAD_TYPE,
  LoadDocumentType,
  LoadInvoiceResponse,
  LoadLocation,
  LoadQboAttributes
} from '../load/load.model';
import { ISearchTagsResponse } from '../loadTags/loadTags.model';
import {
  Trip,
  TRIP_COVERAGE_STATUS,
  TripData,
  TRIP_STATUS,
  TripStop,
  StopStatus,
  TripDriver
} from '../trips/trips.model';
import { Equipment } from '../equipment/equipment.model';
import { DispatchNoteType } from '../../../components/TwDispatchNotes/TwDispatchNotes';
import { USER_ROLE } from '../../../providers/User/User.model';
import { FilterParams } from '../savedViews/savedViews.model';
import { Driver } from '../driver/driver.model';
import { RateTableAttributes } from '../rateTable/rateTable.model';

export enum ORDER_STATUS {
  CANCELLED = 'CANCELLED',
  NOT_STARTED = 'NOT_STARTED',
  IN_TRANSIT = 'IN_TRANSIT',
  DELIVERED = 'DELIVERED'
}

export enum ORDER_INVOICE_STATUS {
  notInvoiced = 'NOT_INVOICED',
  readyForInvoicing = 'READY_FOR_INVOICING',
  invoiceCreated = 'INVOICE_CREATED',
  invoiceSent = 'INVOICE_SENT',
  invoicedManually = 'INVOICED_MANUALLY',
  paid = 'PAID'
}

export enum BOOKING_TYPE {
  FTL = 'FTL',
  LTL = 'LTL'
}

export enum ORDER_COVERAGE_STATUS {
  COVERED = 'COVERED',
  PARTIALLY_COVERED = 'PARTIALLY_COVERED',
  UNCOVERED = 'UNCOVERED'
}

export enum CLIENT_TYPE {
  BROKER = 'BROKER',
  SHIPPER = 'SHIPPER'
}

export enum TZ_USAGE {
  USER = 'USER',
  LOCAL = 'LOCAL'
}

export enum OrderStatusColor {
  CANCELLED = '#EF4444',
  NOT_STARTED = '#9CA3AF',
  IN_TRANSIT = '#3A4DE9',
  DELIVERED = '#10B981'
}

export enum OrderInvoiceStatusColor {
  NOT_INVOICED = '#9CA3AF',
  READY_FOR_INVOICING = '#A855F7',
  INVOICE_CREATED = '#3A4DE9',
  INVOICE_SENT = '#06B6D4',
  INVOICED_MANUALLY = '#06B6D4',
  PAID = '#10B981'
}

export interface OrdersTripsDocumentParams {
  document: File;
  type: LoadDocumentType;
  visibility: boolean;
  entity: 'order' | 'trip' | 'orderRequest';
  tripId?: string | null;
  orderId?: string | null;
  orderRequestId?: string;
  emailId?: string;
  name?: string;
}

export interface UpdateManyOrderdAttachmentParams {
  type: LoadDocumentType;
  visibility: boolean;
  tripId?: string;
  orderId?: string;
  name: string;
  id: string;
}
export interface OrderRate {
  id?: string;
  load_id?: string | null;
  invoice_service_id?: string;
  qbo_service_id?: string;
  qbo_service_type?: string;
  service_name?: string;
  unit: InvoiceServiceUnit | string | null;
  rate: number | null;
  quantity: number;
  amount?: number;
  sort_order: number;
}

export interface OrderCommodities {
  id?: string;
  ui_uid: string;
  description: string | null;
  quantity: number | null;
  weight: number | null;
  packaging_unit: string | null;
}

export interface UpsertOrder {
  id?: string;
  primary_client: CLIENT_TYPE;
  status: ORDER_STATUS;
  tz_usage: TZ_USAGE;
  broker_id?: string | null;
  rate?: number | null;
  order_rates?: OrderRate[];
  customer_order_number?: string;
  send_order_update: boolean;
  configurations?: LoadNotificationSettingsRequest | null;
  dispatcher_id?: string | null;
  trips?: TripData[];
  attachmentList?: AttachmentCopy[];
  documents?: string[];
  customer_contact_id?: string;
  edi_load_tender_id?: string | null;
  order_type: LOAD_TYPE;
  division_id?: string;
  is_brokered_order: boolean;
  payable_amount?: number | null;
  order_commodities: OrderCommodities[];
  override_qbo_attributes?: boolean;
  booking_type: BOOKING_TYPE;
  instructions?: string;
  archived?: boolean;
  invoice_status?: ORDER_INVOICE_STATUS;
  stops?: TripStop[];
  rate_table_id?: string | null;
}

export interface ImportOrderData extends UpsertOrder {
  email_id?: string;
  edi_load_tender_id?: string;
  customer_order_request_id?: string;
  attachments?: ImportedLoadAttachmentData[];
}

export interface AttachmentBasicProps {
  file_type: string;
  id: string;
  key: string;
  name: string;
  s3_key: string;
  s3_signed_url: string;
  s3_signed_download_url: string;
  size: number;
  created_at: string;
}
export interface OrderAttachment {
  attachment: AttachmentBasicProps;
  attachment_id: string;
  document_attachment_id: string | null;
  driver_id: string | null;
  id: string;
  order_id: string;
  trip_id?: string;
  trip_tag?: {
    id: string;
    readable_id: number;
    trip_sort_order: number;
  };
  type: LoadDocumentType;
  uploaded_by: string;
  visibility: boolean;
  is_encrypted: boolean;
  is_generated: boolean | null;
  created_at: string;
  updated_at: string;
  src?: string;
}

export interface Order {
  id: string;
  readable_id: number;
  primary_client: CLIENT_TYPE;
  shipper_id: string | null;
  shipper: ClientResponse | null;
  broker_id: string | null;
  broker: ClientResponse | null;
  raw_customer?: ClientResponse | null;
  full_route_stop_overview?: TripStop[];
  trips: Trip[];
  order_status_notification_configuration?: LoadNotificationSettingsResponse | null;
  order_rates?: OrderRate[];
  rate?: number | null;
  empty_miles: number;
  rate_per_mile: number | null;
  effective_rate: number | null;
  estimated_mileage: number | null;
  deadhead_miles?: number | null;
  bol_missing: boolean;
  status: ORDER_STATUS;
  customer_order_number: string;
  tenant_id: string;
  invoice: LoadInvoiceResponse | null;
  invoice_order_sync_state: InvoiceState;
  archived: boolean;
  order_type: LOAD_TYPE;
  dispatcher_id: string | null;
  dispatcher: DispatcherResponse | null;
  dispatch_note_count: number | null;
  edi_load_tender_id: string | null;
  tz_usage?: TZ_USAGE;
  is_rate_con_model_supported?: boolean;
  division_id: string;
  division?: IDivisionsResponse;
  is_brokered_order: boolean;
  payable_amount?: number | null;
  order_commodities: OrderCommodities[];
  tags: ISearchTagsResponse[];
  has_invoice: boolean;
  is_locked_by_settlement?: boolean;
  qbo_attributes: LoadQboAttributes;
  invoice_status: ORDER_INVOICE_STATUS;
  instructions?: string;
  order_attachments: OrderAttachment[];
  booking_type: BOOKING_TYPE;
  stops: TripStop[]; // exist only for LTL orders
  coverage: ORDER_COVERAGE_STATUS;
  order_margin?: IOrderMargin;
  rate_table_id?: string;
  rate_table?: RateTableAttributes | null;
  is_rate_table_deleted?: boolean;
  is_rate_table_active?: boolean;
  is_division_connected_to_qbo?: boolean;
}

export interface IOrderMargin {
  revenue: number;
  payable_amount: number;
  total_expenses: number;
  driver_pay: number;
}

export interface OrderDriverSearchResponse {
  carrier_id: string | null;
  company_name: string | null;
  deduct_fuel_purchase_on_settlement: boolean;
  default_pay_rate_type: string | null;
  default_trailer_id: string | null;
  default_truck_id: string | null;
  deleted_at: string | null;
  driver_id?: string;
  email_address?: string;
  first_name?: string;
  formatted_pay_rate: string | null;
  id: string;
  initials?: string;
  is_primary: boolean;
  last_name?: string;
  name: string;
  orders_completed?: null | number;
  paid_ytd?: null | number;
  pay_rate: string | null;
  phone: number | null;
  readable_pay_rate_type: null | string;
  tenant_id: string;
  trip_id?: string;
  twuser_id?: string;
  type: null | string;
  updated_at?: string;
}
/**
 * Order search filter params
 * @deprecated in favor of FilterParams
 */
export interface OrderSearchFilterParams {
  pickupFrom?: string; // pickup specific start date
  pickupTo?: string; // pickup specific end date
  deliveryFrom?: string; // delivery specific start date
  deliveryTo?: string; // delivery specific end date
  tripId?: number | string; // trip readable id
  orderId?: string; // order readbale id
  customerOrderNumber?: string;
  broker?: string[]; // broker id
  pickupLocation?: string[]; // array of location ids
  deliveryLocation?: string[]; // array of location ids
  pickupCity?: string;
  deliveryCity?: string;
  pickupState?: string;
  deliveryState?: string;
  driver?: string[]; // array of driver ids
  trailerId?: string; // array of trailer ids
  truckId?: string; // array of truck ids
  status?: ORDER_STATUS[]; // array of trip statuses,
  invoiceStatus?: ORDER_INVOICE_STATUS[];
  includeArchived?: boolean;
  carrier?: string[];
  dispatcher?: string[];
  tagIds?: string[];
  divisionId?: string;
  coverage?: ORDER_COVERAGE_STATUS[];
  bookingMode?: BOOKING_TYPE;
  from?: string;
  to?: string;
  pickupCountry?: string;
  deliveryCountry?: string;
}

export interface SearchOrderParams {
  filters?: FilterParams;
  params?: BaseQueryParams;
}

export interface TripsOnOrderSearchResponse {
  carrier: any;
  carrier_id: string | null;
  delivery_number: null | string;
  drivers: OrderDriverSearchResponse[];
  id: string;
  intermediate_stops_count: number;
  order: number;
  pickup_number: string | null;
  readable_id: number | string;
  start_actual_in_time: null | string;
  start_date: string | null;
  start_from_time: string | null;
  start_location: LoadLocation | null;
  start_location_id: string | null;
  start_to_date: string | null;
  start_to_time: string | null;
  status: TRIP_STATUS;
  stop_actual_out_time: string | null;
  stop_date: string | null;
  stop_from_time: string | null;
  stop_location: LoadLocation | null;
  stop_location_id: string | null;
  stop_to_date: string | null;
  stop_to_time: string | null;
  trailer: Equipment | null;
  truck: Equipment | null;
  truck_id?: string | null;
  stops: TripStop[];
}

export interface SearchOrdersResponse {
  archived: boolean;
  attachments_count: number;
  bol_missing: boolean;
  delivery_date: string | null;
  delivery_from_time: string | null;
  delivery_location: LoadLocation | null;
  delivery_to_date: string | null;
  delivery_to_time: string | null;
  division_id: string;
  division_name: string;
  driver_trip_number: null | string;
  dispatcher: DispatcherResponse | null;
  drivers: OrderDriverSearchResponse[];
  edi_load_tender_id: string | null;
  is_locked_by_settlement: boolean;
  has_invoice: boolean;
  id: string;
  intermediate_stops_count: number;
  invoice_id?: string;
  invoice_status: ORDER_INVOICE_STATUS;
  invoice_sent: boolean;
  is_brokered_order: boolean;
  order_type: LOAD_TYPE;
  order_commodities: OrderCommodities[];
  order_rates: OrderRate[];
  pickup_date: string;
  pickup_from_time: string | null;
  pickup_location: LoadLocation | null;
  pickup_to_time: string | null;
  primary_client: CLIENT_TYPE;
  primary_client_id: string;
  primary_client_name: string;
  rate: number;
  rate_per_mile: number;
  readable_id: number | string;
  customer_order_number: string;
  status: ORDER_STATUS;
  tags: ISearchTagsResponse[];
  trips: TripsOnOrderSearchResponse[];
  trips_count: number;
  truck: Equipment;
  division: IDivisionsResponse;
  booking_type: BOOKING_TYPE;
  coverage: ORDER_COVERAGE_STATUS;
}

export interface CreateDispatchNoteResponse {
  dispatch_note: {
    created_at: Date;
    created_by: string;
    id: string;
    note: string;
    note_type: DispatchNoteType;
    order_id: string | null;
    trip_id: string | null;
    updated_at: Date;
    updated_by: string;
  };
  user: {
    first_name: string;
    id: string;
    initials: string;
    last_name: string;
    role: USER_ROLE;
  };
}

/**
 * Share order preview params
 */
export interface OrderSharePreviewParams {
  driver_name?: string;
  driver_id: string;
  via_email: boolean;
  via_sms: boolean;
  trip_id?: string;
  message_type?: 'UPDATE' | 'UNASSIGNED' | 'SHARE';
  find_segment_for_driver?: boolean;
  deleted_trip_index?: number;
}

export interface EdiOrderSearchResponse {
  id: string;
  customer: ClientResponse;
  order_data: Partial<SearchOrdersResponse>;
  tender_date: string;
}

export interface EdiOrder {
  id: string;
  customer: ClientResponse;
  order_data: Partial<Order>;
}

export interface SaveOrderAsPDFParams {
  orderId: string;
  attachments: string[];
  include_invoice: boolean;
}

export interface SendOrderInfoByEmailParams {
  email_message: string;
  send_to: string[];
  tenant_cc: boolean;
  subject: string;
  attachments: string[];
  include_invoice: boolean;
  merge_attachments: boolean;
}

export interface GetSignatureSourceParams {
  orderId: string;
  stopId: string;
  signatureId: string;
}

export interface GetSignatureSourceByTokenParams {
  token: string;
  stopId: string;
  signatureId: string;
}

export interface UpdateOrderStopByTokenParams {
  trip_id?: string;
  stop_id?: string;
  stop_status?: StopStatus;
  stop_in_time?: string;
  stop_out_time?: string;
}

export interface AutocompleteOrderSearchResponse {
  archived: boolean;
  customer_order_number: string;
  edi_load_tender_id: string | null;
  id: string;
  is_brokered_order: boolean;
  order_type: LOAD_TYPE;
  primary_client_name: string;
  readable_id: number | string;
  status: ORDER_STATUS;
}

export interface PrefillOrderDataResponse {
  stops: TripStop[];
  status: ORDER_STATUS;
  readable_id: number;
  order_commodities: OrderCommodities[];
  id: string;
  customer_order_number: string | null;
  raw_customer: ClientResponse;
}

export type TenantSettingsKeys =
  | 'allow_drivers_to_view_documents'
  | 'multiple-carriers'
  | 'multiple-dispatchers'
  | 'require-bol'
  | 'advanced-driver-fields'
  | 'use-24-hour-clock'
  | 'show-customer-contact-on-dispatch'
  | 'gross-pay-on-dispatch'
  | 'driver-pay-percentage-based-on-default-service'
  | 'divisions'
  | 'allow-drivers-to-edit-truck-and-trailer'
  | 'customer-access-trips'
  | 'enable-watermark-feature'
  | 'load-commodities'
  | 'enable-service-id'
  | 'show-customer-order-attachments'
  | 'allow-drivers-to-edit-miles'
  | 'empty-miles';

export interface SharedLinkTenant {
  id: string;
  company_name: string;
  offset: string;
  short_offset: string;
  timezone: string;
  tenantSettings: { [key in TenantSettingsKeys]: boolean };
  ltlEnabled: boolean;
}

export interface SharedTokenResponse {
  // should be returning full driver object now
  driver: {
    id: string;
    name: string;
    first_name?: string;
    last_name?: string;
    email_address?: string;
    twuser_id?: string;
  } | null;
  linkSegmentId: string | null;
  isDriverAssignedToTrip: boolean;
  order: Order | null;
  trip: Trip | null;
  tenant: SharedLinkTenant;
}

export interface SearchOrderFieldResponse {
  id: string;
  customer_order_number: string;
  readable_id: number;
  primary_client: CLIENT_TYPE;
  broker: ClientResponse | null;
  shipper: ClientResponse | null;
}

export interface OrderDriverLinksResponse {
  order: Order;
  trips: { trip: Trip; drivers: { driver: TripDriver; link: string }[] }[];
}
