import { UserResponse } from '../providers/User/User.model';
import { SharedTokenResponse } from '../redux/api/orders/orders.model';
import * as amplitude from '@amplitude/analytics-browser';

// add enum of the various events we want to track
export enum AmplitudeEvent {
  // Document processing metrics
  DYNAMSOFT_SCAN = 'DYNAMSOFT_SCAN',
  // SCANBOT_SCAN_ATTEMPT = 'SCANBOT_SCAN_ATTEMPT',
  // SCANBOT_SCAN_ERROR = 'SCANBOT_SCAN_ERROR',

  // Google Maps Platform metrics
  GOOGLE_DIRECTIONS_REQUEST = 'GOOGLE_DIRECTIONS_REQUEST',
  GOOGLE_PLACES_REQUEST = 'GOOGLE_PLACES_REQUEST',
  GOOGLE_MAPS_REQUEST = 'GOOGLE_MAPS_REQUEST',
  GOOGLE_ROUTES_REQUEST = 'GOOGLE_ROUTES_REQUEST',
  GOOGLE_TIMEZONE_REQUEST = 'GOOGLE_TIMEZONE_REQUEST',
  GOOGLE_GEOCODER_REQUEST = 'GOOGLE_GEOCODER_REQUEST'
}

// add function to initialize amplitude
export const initializeAmplitude = (key: string): void => {
  try {
    console.log('Initializing Amplitude!');
    amplitude.init(key);
  } catch (error) {
    console.error('Failed to initialize Amplitude:', error);
  }
};

// check if amplitude should be used
const shouldUseAmplitude = (): boolean => {
  // This value is updated with the docker-entrypoint.sh script so based on environment variables set in terraform, it'll be either the actual key or an empty string to indicate it should not be used
  const amplitudeApiKey = '3864577d5d378d35b78a515c47f0635c';
  return amplitudeApiKey.length > 0;
};

// log event
export const logAmplitudeEvent = (event: AmplitudeEvent, properties?: any): void => {
  try {
    if (shouldUseAmplitude()) {
      amplitude.track(event, properties);
    } else {
      console.log('Amplitude event (not initialized):', { event, properties });
    }
  } catch (error) {
    console.error('Failed to track Amplitude event:', {
      error,
      event,
      properties
    });
  }
};

// log user properties
export const identifyAmplitudeUser = (user: UserResponse): void => {
  try {
    if (!shouldUseAmplitude()) {
      console.log('Amplitude user identification (not initialized):', {
        userId: user.id,
        email: user.email_address,
        firstName: user.first_name,
        lastName: user.last_name,
        tenant: user.tenant
          ? {
              id: user.tenant.id,
              companyName: user.tenant.company_name
            }
          : null
      });
      return;
    }

    const identity = new amplitude.Identify();
    identity.set('email', user.email_address);
    identity.set('firstName', user.first_name);
    identity.set('lastName', user.last_name);
    if (user.tenant) {
      identity.set('tenantId', user.tenant.id || '');
      identity.set('companyName', user.tenant.company_name || '');
    }
    amplitude.identify(identity, {
      user_id: user.id
    });
  } catch (error) {
    console.error('Failed to identify Amplitude user:', {
      error,
      userId: user.id,
      email: user.email_address
    });
  }
};

export const identifyAmplitudeUserFromLink = (link: SharedTokenResponse): void => {
  try {
    if (!shouldUseAmplitude()) {
      console.log('Amplitude user identification from link (not initialized):', {
        tenantId: link.tenant.id,
        tenantCompanyName: link.tenant.company_name,
        driver: link.driver
          ? {
              email: link.driver.email_address,
              firstName: link.driver.first_name,
              lastName: link.driver.last_name,
              twuserId: link.driver.twuser_id
            }
          : null
      });
      return;
    }

    const identity = new amplitude.Identify();

    // add tenant data
    identity.set('tenantId', link.tenant.id || '');
    identity.set('companyName', link.tenant.company_name || '');

    // if driver is not null, add driver data
    if (link.driver) {
      identity.set('email', link.driver.email_address || '');
      identity.set('firstName', link.driver.first_name || '');
      identity.set('lastName', link.driver.last_name || '');
    }

    // create eventOptions to add if driver is not null and twuser_id is not null otherwise empty object
    const eventOptions =
      link.driver && link.driver.twuser_id
        ? {
            user_id: link.driver.twuser_id
          }
        : {};
    amplitude.identify(identity, eventOptions);
  } catch (error) {
    console.error('Failed to identify Amplitude user from link:', {
      error,
      tenantId: link.tenant.id,
      driverId: link.driver?.twuser_id
    });
  }
};
