import { GridSelectionModel } from '@mui/x-data-grid';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

import { AnalyticValues } from '../../../pages/Settlements/Builder';
import {
  ActiveSettlementSlice,
  SettlementsBuilderTable,
  SettlementsSavedTable
} from '../../api/settlement/settlement.model';

export enum SelectionMode {
  VIEW = 'VIEW',
  EDIT = 'EDIT'
}

export interface ISettlementState {
  // State across all settlement tabs (builder/saved)
  selectedSettlements: GridSelectionModel;
  // State for builder (filters, etc)
  firstSelection: boolean;
  rangeDates: { start: string; end: string } | null;
  driversIds: string[];
  showTable: boolean;
  settlementsBuilder: SettlementsSavedTable[];
  refreshBuilderTable: boolean;
  shouldBuilderReset: boolean;
  analyticTiles: AnalyticValues;
  // States for draft settlements tab
  draftSettlements: SettlementsSavedTable[];
  draftAnalyticTiles: AnalyticValues;
  showDraftTable: boolean;
  // State for overview
  activeSettlement: ActiveSettlementSlice | null;
  settlementDriverId: string | null;
  copyActiveSettlement: ActiveSettlementSlice | null;
  // for delete
  isDeleteConfirmModalOpen: boolean;
  isSendSettlementAsEmailModalOpen: boolean;
  settlementIdForEmail: string | null;
  idToDelete: string | null;
  isDeleteSuccess: boolean | null;

  // revert paid settlements state
  markAsDraftSuccess: boolean | null;
}

const initialState: ISettlementState = {
  selectedSettlements: [],
  rangeDates: null,
  driversIds: [],
  showTable: false,
  firstSelection: true,
  settlementsBuilder: [],
  activeSettlement: null,
  copyActiveSettlement: null,
  shouldBuilderReset: true,
  isDeleteConfirmModalOpen: false,
  isSendSettlementAsEmailModalOpen: false,
  idToDelete: null,
  settlementIdForEmail: null,
  isDeleteSuccess: null,
  analyticTiles: {} as AnalyticValues,
  settlementDriverId: null,
  refreshBuilderTable: false,
  markAsDraftSuccess: null,
  draftSettlements: [],
  draftAnalyticTiles: {} as AnalyticValues,
  showDraftTable: false
};

export const settlementSlice = createSlice({
  name: 'settlement',
  initialState,
  reducers: {
    resetAll: () => initialState,
    resetBuilder: (state) => ({
      ...initialState
    }),
    resetViewEditState: (state) => ({
      ...state,
      selectionMode: SelectionMode.EDIT
    }),

    updateSelectedSettlements: (state, action: PayloadAction<GridSelectionModel>) => {
      state.selectedSettlements = action.payload;
    },

    updateSettlementsBuilder: (state, action: PayloadAction<SettlementsSavedTable[]>) => {
      state.settlementsBuilder = action.payload;
    },
    updateDraftSettlements: (state, action: PayloadAction<SettlementsSavedTable[]>) => {
      state.draftSettlements = action.payload;
    },
    updateRangeDates: (state, action: PayloadAction<{ start: string; end: string } | null>) => {
      state.rangeDates = action.payload;
    },
    updateDriversIds: (state, action: PayloadAction<string[]>) => {
      state.driversIds = action.payload;
    },
    updateShowTable: (state, action: PayloadAction<boolean>) => {
      state.showTable = action.payload;
    },
    updateShowDraftTable: (state, action: PayloadAction<boolean>) => {
      state.showDraftTable = action.payload;
    },
    updateFirstSelection: (state, action: PayloadAction<boolean>) => {
      state.firstSelection = action.payload;
    },
    updateActiveSettlement: (state, action: PayloadAction<ActiveSettlementSlice | null>) => {
      state.activeSettlement = action.payload;
    },
    setCopyActiveSettlement: (state, action: PayloadAction<ActiveSettlementSlice | null>) => {
      state.copyActiveSettlement = action.payload;
    },
    deleteSettlement: (state, action: PayloadAction<string>) => {
      state.idToDelete = action.payload;
      state.isDeleteConfirmModalOpen = true;
    },
    updateDeleteConfirmModalState: (state, action: PayloadAction<boolean>) => {
      state.isDeleteConfirmModalOpen = action.payload;
    },
    sendSettlementAsEmail: (state, action: PayloadAction<string>) => {
      state.settlementIdForEmail = action.payload;
      state.isSendSettlementAsEmailModalOpen = true;
    },
    updateSendSettlementAsEmailModalState: (state, action: PayloadAction<boolean>) => {
      state.isSendSettlementAsEmailModalOpen = action.payload;
    },
    resetDeleteErrorState: (state) => {
      state.isDeleteSuccess = null;
    },
    updateAnalyticsOnBuilder: (state, action: PayloadAction<AnalyticValues>) => {
      state.analyticTiles = action.payload;
    },
    updateDraftAnalytics: (state, action: PayloadAction<AnalyticValues>) => {
      state.draftAnalyticTiles = action.payload;
    },
    updateSettlementDriverId: (state, action: PayloadAction<string | null>) => {
      state.settlementDriverId = action.payload;
    },
    updateRefreshBuilderTable: (state, action: PayloadAction<boolean>) => {
      state.refreshBuilderTable = action.payload;
    },
    updateDeleteSettlementState: (
      state,
      action: PayloadAction<{ deleteSuccess: boolean; modalOpen: boolean }>
    ) => {
      const { deleteSuccess, modalOpen } = action.payload;
      state.isDeleteSuccess = deleteSuccess;
      state.isDeleteConfirmModalOpen = modalOpen;
      state.idToDelete = null;
    },
    updateShouldBuilderReset: (state, action: PayloadAction<boolean>) => {
      state.shouldBuilderReset = action.payload;
    },
    updateMarkAsDraftSuccess: (state, action: PayloadAction<boolean | null>) => {
      state.markAsDraftSuccess = action.payload;
    }
  }
});

export const selectIsDeleteConfirmModalOpen = (state: RootState): boolean =>
  state.settlement.isDeleteConfirmModalOpen;

export const {
  resetAll,
  resetBuilder,
  resetViewEditState,
  updateSelectedSettlements,
  updateActiveSettlement,
  setCopyActiveSettlement,

  updateDriversIds,
  updateRangeDates,
  updateSettlementsBuilder,
  updateFirstSelection,
  updateShowTable,
  updateShowDraftTable,
  updateShouldBuilderReset,

  deleteSettlement,
  resetDeleteErrorState,
  updateDeleteConfirmModalState,
  updateAnalyticsOnBuilder,
  sendSettlementAsEmail,
  updateSendSettlementAsEmailModalState,
  updateSettlementDriverId,
  updateRefreshBuilderTable,

  updateDeleteSettlementState,
  updateMarkAsDraftSuccess,
  updateDraftSettlements,
  updateDraftAnalytics
} = settlementSlice.actions;

export default settlementSlice.reducer;
