import { TwSelectOption } from '../../../components/TwSelects/TwSelects';
import { BaseQueryParams } from '../../Global.model';

export enum InvoiceServiceUnit {
  FLAT = 'FLAT',
  PER_MILE = 'PER_MILE',
  PER_HOUR = 'PER_HOUR',
  PER_DAY = 'PER_DAY',
  PER_STOP = 'PER_STOP',
  PER_UNIT = 'PER_UNIT',
  PERCENTAGE = 'PERCENTAGE'
}

export enum InvoiceSources {
  TB = 'Truckbase',
  QBO = 'Quickbooks'
}

export enum InvoiceServiceStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED'
}

export enum DefaultPaymentTerms {
  WITHIN_15_DAYS = 'WITHIN_15_DAYS',
  WITHIN_30_DAYS = 'WITHIN_30_DAYS',
  WITHIN_45_DAYS = 'WITHIN_45_DAYS',
  WITHIN_60_DAYS = 'WITHIN_60_DAYS',
  UPON_RECEIPT = 'UPON_RECEIPT',
  OTHER = 'OTHER'
}

export const PAYMENT_TERMS: TwSelectOption[] = [
  {
    label: 'Within 15 days',
    value: DefaultPaymentTerms.WITHIN_15_DAYS
  },
  {
    label: 'Within 30 days',
    value: DefaultPaymentTerms.WITHIN_30_DAYS
  },
  {
    label: 'Within 45 days',
    value: DefaultPaymentTerms.WITHIN_45_DAYS
  },
  {
    label: 'Within 60 days',
    value: DefaultPaymentTerms.WITHIN_60_DAYS
  },
  {
    label: 'Upon receipt',
    value: DefaultPaymentTerms.UPON_RECEIPT
  },
  {
    label: 'Other',
    value: DefaultPaymentTerms.OTHER
  }
];

export interface InvoicePreferencesSettings {
  default_payment_terms: DefaultPaymentTerms;
  additional_invoice_recipients: string[];
}

export interface InvoiceService {
  id: string;
  name: string;
  unit: InvoiceServiceUnit;
  default_rate: number;
  status: InvoiceServiceStatus;
  type?: string;
  isDefault?: boolean;
  divisions_defined_as_default?: string[];
  external_service_id?: string | null;
}

export interface CreateInvoiceServiceParams {
  name: string;
  unit: InvoiceServiceUnit;
  default_rate?: number | undefined;
  external_service_id?: string;
}

export interface SearchInvoiceServicesParams extends BaseQueryParams {
  source?: InvoiceSources;
}

export interface UpdateInvoiceServiceParams {
  id: string;
  name: string;
  unit: InvoiceServiceUnit;
  default_rate?: number | undefined;
  external_service_id?: string | null;
  status?: InvoiceServiceStatus;
}

export interface UpdateInvoiceServiceStatusParams {
  id: string;
  status: InvoiceServiceStatus;
}

export interface InvoiceServiceSearchFilters {
  createdBeforeDate?: Date;
  from?: string;
  to?: string;
  invoiceServicesIds?: string[];
  includeDisabled?: boolean;
  status?: InvoiceServiceStatus[];
  divisionId: string;
}

export const LINEHAUL_FLAT_RATE_NAME = 'Linehaul flat rate';

const invoiceServiceUnitKeys = [...Object.keys(InvoiceServiceUnit)];

const uniqueInvoiceServiceUnitKeys = new Set(invoiceServiceUnitKeys);

export const getInvoiceServiceUnitLabel = (unit: string): string => {
  if (uniqueInvoiceServiceUnitKeys.has(unit)) {
    if (unit === InvoiceServiceUnit.PERCENTAGE) {
      return '% of linehaul';
    }

    return (unit.charAt(0).toUpperCase() + unit.slice(1).toLowerCase()).replaceAll('_', ' ');
  }

  return unit;
};
